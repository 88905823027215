.header {
	@apply flex;
	@apply justify-between;
	@apply gap-4;
	@apply border-light-gray-400;
	@apply pt-48;
	@apply pb-24;

	@screen sm {
		@apply pb-16;
		@apply px-24;
	}

	@screen md {
		@apply px-32;
	}

	@screen lg {
		@apply px-80;
	}

	@screen land-sm {
		@apply pt-36;
		@apply pb-16;
		@apply px-32;
	}

	@screen land-md {
		@apply px-80;
	}
}
