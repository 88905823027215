@use '../../../styles/colours';

.Description > p > a {
	color: colours.$primary-purple;
	text-decoration: underline;
	text-underline-offset: 2px;
	padding-bottom: 1px;
	cursor: pointer;
}

//Need to remove the marging top for the first paragraph
.Description > p:first-child {
	margin-top: 0;
}

.Description > p {
	margin-top: 2rem;
}
