@use './_colours';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html,
body {
	margin: 0;
	padding: 0;
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #222222;

	@apply bg-gray-background;

	@screen sm {
		@apply text-sm;
	}
	@screen land-sm {
		@apply text-sm;
	}
}

body {
	margin: 0 auto;
	font-weight: 400;
}

h1,
h2 {
	color: #243075;
	font-size: 24px;
	line-height: 2.5rem;
}

h3 {
	font-size: 20px;
	line-height: 2rem;
	font-weight: 700;
}

h4 {
	font-weight: 700;
}

.border {
	display: none;
}

#root {
	height: 100vh;
}

.react-player__preview {
	border-radius: 0.5rem;
}

a {
	color: #7684ff;
	text-decoration: underline;
	font-weight: lighter;

	&:hover {
		opacity: 0.7;
	}
	&:active {
		opacity: 1;
		text-decoration: none;
	}
}

img,
iframe {
	height: auto;
	// float: left;
}

iframe {
	// min-width: 40em;
	min-height: 25em;
	width: 100%;
	flex-shrink: 0;
}

// Blog Data Styles
.blog-content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	.photogrid {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		p:first-child {
			@apply w-full m-0;
			@apply text-3xl;
			@apply text-blue;
			@apply leading-8;

			@screen sm {
				@apply text-xl;
			}
			@screen land-sm {
				@apply text-xl;
			}
		}

		p {
			@apply w-full m-0;
		}

		.photogrid-container {
			display: flex;
			width: 100%;
			max-width: 1280px;
			margin: 0 auto;
			flex-wrap: wrap;
			justify-content: center;
			gap: 1.5rem;

			.photogrid-item {
				flex: 1 1 300px;
				max-width: 640px;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
					max-height: 400px;
				}
			}
		}
	}

	> img {
		@apply min-w-full;
	}

	> figcaption {
		@apply text-[15px] font-normal;
		@apply text-gray;
	}

	> iframe {
		@apply min-w-full;
	}

	> ul {
		list-style: disc;
	}

	> ol {
		list-style: decimal;
	}

	> ul,
	> ol {
		@apply pl-9;
	}

	> p {
		margin: -0.5rem 0;
	}
}

// Fix Lightbox buttons
.ril__navButtonPrev {
	left: 0;
	background: rgba(0, 0, 0, 0.2)
		url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
		no-repeat center;
}

.ril__navButtonNext {
	right: 0;
	background: rgba(0, 0, 0, 0.2)
		url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
		no-repeat center;
}

.ril__closeButton {
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
		no-repeat center;
}

.ril__zoomInButton {
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
		no-repeat center;
}

.ril__zoomOutButton {
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
		no-repeat center;
}
